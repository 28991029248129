import { createStore } from 'vuex'
import { auth, db} from '@/firebase/gfbconf'
import axios from 'axios';

export default createStore({
  state: {
    applicationMode: "PRODUCTION",
    apiLink: "production",
    apiKey: "",
    userId: "",
    userKey: "",
    showNotification: false,
    notification: {
      show: false,
      head: "",
      subheader: "",
      success: true,
    },
    user: null,
    userData: null,
    authIsReady: false,
    globalLoader: {
      show: true,
      head: "Ładowanie..",
      subheader: "Nie zamykaj tego okna."
    }
  },
  mutations: {
    setNotification(state, notification)
    {
      state.notification = notification;
      setTimeout(()=>
      {
        state.notification = {
          show: false,
          head: "",
          subheader: "",
          success: true,
        }
      },3000)
    },
    setUrl(state, url)
    {
      state.url = url
    },
    // Set user to Vuex store
    setUser(state, userData)
    {
      state.user = userData;
    },
    updateInternetConnectionStatus(state, status)
    {
      state.internetConnection = status;
    },
    setUserCredentials(state, userData)
    {
      state.userData = userData;
      if(!userData.role.notificationReaded) {
        state.showNotification = !userData.role.notificationReaded;
      }
    },
    setAuthIsReady(state,payload)
    {
      state.authIsReady = payload;
    },
    // reset global loader
    resetGlobalLoader(state)
    {
      state.globalLoader = {
        show: false,
        head: "Ładowanie..",
        subheader: "Nie zamykaj tego okna."
      }
    },
    setGlobalLoader(state,context)
    {
      state.globalLoader = context;
    },

  },
  actions: {
    // login new user
    async login(context,payload)
    {
      try {
        const signIn = await auth.signInWithEmailAndPassword(payload.email, payload.password);
        if (signIn) {
          context.commit('setGlobalLoader',{
            show: true,
            head: "Sprawdzanie twoich danych..",
            subheader: `Nie zamykaj okna.`,
            success: false
        });
          let claims = (await signIn.user.getIdTokenResult()).claims
          const res = await axios.post(`${context.state.apiLink}/users/get`,{}, {
            headers: {
              "apikey": context.state.apiKey,
              "userkey": claims.userKey,
              "userid": claims.userId
            }
          })
          console.log("User signed in");
          context.state.userId = claims.userId
          context.state.userKey = claims.userKey

          if(res.data.status === 'SUCCESS') {
            context.commit('setUserCredentials', res.data.userData);
            context.commit('setUser', signIn.user);
          } else {
            context.commit('setGlobalLoader',{
                show: false,
                head: "Sprawdzanie twoich danych..",
                subheader: `Nie zamykaj okna.`,
                success: false
            });
            throw new Error("Something went wrong when getting userData")
          }
        } else {
          context.commit('setGlobalLoader',{
              show: false,
              head: "Sprawdzanie twoich danych..",
              subheader: `Nie zamykaj okna.`,
              success: false
          });
          throw new Error("Could not complete login action.")
        }
      } catch(error) {
        context.commit('setGlobalLoader',{
            show: false,
            head: "Sprawdzanie twoich danych..",
            subheader: `Nie zamykaj okna.`,
            success: false
        });
        throw new Error("Could not complete login action.")
      }
    },
    async resetPassword(context,payload)
    {
      try {
        auth.sendPasswordResetEmail(payload.email)
      } catch(error) {
        throw new Error("Could not send password reset.")
      }
    },
    // logout user;
    async logout(context)
    {
      context.state.authIsReady = false;
      await auth.signOut();
      context.commit('setUser', null);
    }
  },
  modules: {
  }
})
