import firebase from 'firebase/compat/app';
import { getAnalytics } from "firebase/analytics";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

let firebaseConfig
if(process.env.VUE_APP_VERSION === 'PRODUCTION_LOCAL' || process.env.VUE_APP_VERSION === 'PRODUCTION') {
  firebaseConfig = {
    apiKey: "AIzaSyANpDyhUuOlrKSyLA92pazI4eb_GzwCs_M",
    authDomain: "honesty-app-14db7.firebaseapp.com",
    projectId: "honesty-app-14db7",
    storageBucket: "honesty-app-14db7.appspot.com",
    messagingSenderId: "158363498836",
    appId: "1:158363498836:web:4b06b2058ad4de24a030c1",
    measurementId: "G-QSS5Q655RF"
  };
} else if(process.env.VUE_APP_VERSION === 'SANDBOX_LOCAL' || process.env.VUE_APP_VERSION === 'SANDBOX') {
  firebaseConfig = {
    apiKey: "AIzaSyCpX4eqynPs9ImDwc7-W6sVTLWIGVK9WcE",
    authDomain: "honesty-app-sandbox.firebaseapp.com",
    projectId: "honesty-app-sandbox",
    storageBucket: "honesty-app-sandbox.appspot.com",
    messagingSenderId: "900900464878",
    appId: "1:900900464878:web:695b4eb500433b9d60ebff"
  };
}

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  
  //const db = firebase.firestore()
  const auth = firebase.auth();
  const db = firebase.firestore();
  const storage = firebase.storage();
  const app = firebase.app()
  const analytics = getAnalytics(app);

export { firebase, auth, db, storage, app, analytics };